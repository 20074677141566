<template>
  <div receiver-payletter>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';

export default {
  name: 'ReceiverPayletter',
  mounted() {
    close(this);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>